<template>
  <div class="home">
    <div class="content">
      <div class="maintenance">
        <h1> Технические работы </h1>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
}
</script>

<style>
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300);

body, html {
  height: 100%;
  width: 100%;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1em;
  background: #5471B9;
}
.maintenance h1 {
  font-size: 190%; 
  color: #fff;
  text-shadow: -2px 2px 3px rgba(0,0,0,0.5)
}
.maintenance {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -100px;
  fill: #fff;
  -webkit-filter: drop-shadow(-2px 2px 3px rgba(0,0,0,0.5));
  -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=2, OffY=2, Color='#444')";
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=2, OffY=2, Color='#444')";
}
.content {
  height: 100%;
  width: 100%;
  background-color: #5471B9;
}
</style>
